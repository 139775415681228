import axios from 'axios';
import create from 'zustand';
import createStore from 'zustand/vanilla';
import { default as i18n } from 'i18next';
import { soundType } from 'constants/sounds/sounds';
import { WebsocketBuddy } from '../services/websockets';
import type { DataState, BoatDataState, PackageDataState, SetFunction } from './types';

const language = i18n.language;

export const apiBaseUrl = process.env.REACT_APP_API_URL;
export const downloadUrl = process.env.REACT_APP_DOWNLOAD_URI;

export const experimentStore = createStore<any>(() => ({
  play: (experiment: any) => axios?.post(`${apiBaseUrl}/experiment/play`, experiment),
}));

export const useAddressStore = create<any>(() => ({
  updateCustomerAddress: async (bookingId, address) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/booking/${bookingId}/attachCustomerAddress`,
        address
      );

      return response.data;
    } catch (err) {
      console.error('Failed to update customer address', err);

      throw err;
    }
  },
  updateCustomerPoNumber: async (bookingId, poNumber) => {
    try {
      const response = await axios.put(`${apiBaseUrl}/booking/${bookingId}/attachPoNumber`, {
        po_number: poNumber,
      });

      return response.data;
    } catch (err) {
      console.error('Failed to update PO number', err);
      throw err;
    }
  },
}));

export const useChatStore = create<any>((set, get) => ({
  messages: [],
  newMessage: '',
  setNewMessage: (message: string) => set({ newMessage: message }),
  fetchMessages: async (bookingId: string) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/messages/${bookingId}`);
      set({ messages: response.data.data });
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  },
  sendMessage: async (bookingId: string, customerId: string | null, message: string) => {
    try {
      await axios.post(`${apiBaseUrl}/messages`, {
        booking_id: bookingId,
        customer_id: customerId,
        message,
      });

      set({ newMessage: '' });

      get().fetchMessages(bookingId);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  },
  subscribeToPusher: (bookingId: string) => {
    const channel = `bookings.${bookingId}`;
    const listeners = {
      'message.sent': (data) => {
        if (data?.userId) {
          const newMessageAudio = new Audio(soundType.newMessage);
          newMessageAudio.load();
          newMessageAudio.play().catch((error) => {
            console.error('Error playing audio:', error);
          });
        }

        get().fetchMessages(bookingId);
      },
    };

    const { pusher } = WebsocketBuddy({
      channel,
      driver: 'Pusher',
      listeners,
    });

    // Clean up the subscription on component unmount
    return () => {
      pusher.unsubscribe(channel);
    };
  },
}));

export const useBoatStore = create<BoatDataState>((set: SetFunction) => ({
  id: '',
  data: {
    id: '',
    name: '',
    availableProducts: [],
    availableTripDurations: [],
    capacity: 0,
    description: '',
    includedItems: [],
    image: {
      alt: '',
      url: '',
    },
    minGroupSize: 0,
    pricingType: '',
    priceInCents: 0,
    surcharges: [],
  },
  fetching: false,
  loading: true,
  hasErrors: false,
  type: 'boat',
  fetch: async (boatId: string) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/boat/${boatId}?language=${language}`);

      const update = {
        data: response.data,
        loading: false,
        fetching: false,
        hasErrors: false,
        id: boatId,
      };

      set(update);

      return update;
    } catch (err) {
      const errors = { hasErrors: true, loading: false };

      set(errors);

      return errors;
    }
  },
}));

export const useBookingStore = create<any>(() => ({
  submit: (data: any) => {
    const modifiedData = { ...data };

    modifiedData.selectedProducts = modifiedData.selectedProducts.filter(
      ({ id }) => id !== 'no_drinks'
    );

    return axios.post(`${apiBaseUrl}/booking?language=${language}`, modifiedData);
  },
}));

export const useCardPanelStore = create<DataState>((set: SetFunction) => ({
  infoPanel: '',
  data: {
    content: '',
    imageSrc: '',
    label: '',
    price: '',
    priceClean: '',
    title: '',
  },
  setInfoPanel: (key: string, data: any) =>
    set(() => ({
      infoPanel: key,
      data,
    })),
  closeInfoPanel: () =>
    set(() => ({
      infoPanel: '',
      data: {},
    })),
}));

export const useInvoiceStore = create<any>((set: SetFunction) => ({
  id: '',
  data: {
    id: '',
    name: '',
    booking: {},
    lineItems: [],
  },
  loading: true,
  fetching: false,
  hasErrors: false,
  fetch: async (invoiceId: string) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/invoice/${invoiceId}?language=${language}`);

      set({
        data: response.data,
        fetching: false,
        loading: false,
        hasErrors: false,
        id: invoiceId,
      });
    } catch (err) {
      set({
        hasErrors: true,
        loading: false,
      });
    }
  },
  downloadInvoice: (quoteId: string) => {
    const url = `${downloadUrl}/quote/${quoteId}/invoices`;
    window.open(url, '_blank');
  },
}));

export const usePackageStore = create<PackageDataState>((set: SetFunction) => ({
  id: '',
  data: {
    id: '',
    name: '',
    availableProducts: [],
    availableTripDurations: [],
    capacity: 0,
    description: '',
    image: {
      alt: '',
      url: '',
    },
    includedItems: [],
    pricePerPersonInCents: 0,
    surcharges: [],
  },
  loading: true,
  fetching: false,
  hasErrors: false,
  type: 'package',
  fetch: async (packageId: string) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/package/${packageId}?language=${language}`);

      const update = {
        data: response.data,
        fetching: false,
        loading: false,
        hasErrors: false,
        id: packageId,
      };

      set(update);

      return update;
    } catch (err) {
      const errors = { hasErrors: true, loading: false };

      set(errors);

      return errors;
    }
  },
}));

export const useQuoteStore = create<any>((set: SetFunction) => ({
  id: '',
  data: {
    id: '',
    name: '',
    booking: {},
    lineItems: [],
  },
  paying: false,
  loading: true,
  fetching: false,
  hasErrors: false,
  fetch: async (quoteId: string) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/quote/${quoteId}?language=${language}`);

      set({
        data: response.data,
        fetching: false,
        loading: false,
        hasErrors: false,
        id: quoteId,
      });
    } catch (err) {
      set({
        hasErrors: true,
        loading: false,
      });
    }
  },
  pay: (quoteId: string, remarks: string) => {
    set({ paying: true });

    return axios
      .post(`${apiBaseUrl}/quote/${quoteId}/payment`, {
        remarks,
      })
      .then((response) => {
        window.location.href = response.data.redirectUrl;
      })
      .catch((error) => ({ error }))
      .finally(() => {
        set({ paying: false });
      });
  },
  requestChanges: (quoteId: string, remarks: string) =>
    axios
      .post(`${apiBaseUrl}/quote/${quoteId}/requestChanges`, {
        remarks,
      })
      .then((response) => {
        window.location.href = response.data.redirectUrl;
      })
      .catch((error) => ({ error })),
}));

export const useTripStore = create<any>((set) => ({
  id: '',
  data: {
    id: '',
    date: '',
    groupSize: 0,
    poNumber: '',
    company: {
      name: '',
      chamberOfCommerceNumber: '',
      vatNumber: '',
      region: {
        name: '',
      },
    },
    customer: {
      name: '',
      isBusiness: false,
      businessName: '',
      id: '',
      address: {
        city: '',
        country: '',
        housenumber: '',
        streetname: '',
        zipcode: '',
      },
    },
    lineItems: [],
    vatRates: [],
    quote: null,
    invoice: null,
  },
  loading: true,
  hasErrors: false,
  fetch: async (bookingId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/booking/${bookingId}`);

      set({
        data: response.data,
        loading: false,
        hasErrors: false,
        id: bookingId,
      });
    } catch (err) {
      set({
        hasErrors: true,
        loading: false,
      });
    }
  },
}));
